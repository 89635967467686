@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");


:root {
	--mainColor:transparent;
	--mainColors: #1e1f23f8;
	--mainColorLight: #ffd240;
	--secondaryColor: #ffc62b;
	--textColor: #ffffff;
}
.head{
   background-color: #1E1F23 !important;

  
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height:50px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
    z-index: inherit !important;
}

nav .a {
	margin: 0 4rem;
	color: var(--textColor);
	text-decoration: none !important;
	overflow: hidden;
	position: relative;
	display: inline-block;
	
}
.a::before,
.a::after {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.a::before {
 
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}
.a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  
}

.a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.a:hover::after {
  transform: translate3d(0, 0, 0);
}

.a span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

a:hover span {
  transform: translate3d(-200%, 0, 0);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor) !important;
	visibility: hidden;
	opacity: 0;
	font-size: 1.2rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
        z-index: 100000000000000 !important;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5rem;
		background-color: var(--mainColors);
		transition: 1s;
	
	}

	header .responsive_nav {
		transform: translateY(100vh);
        z-index: 100000000000000 !important;
	
	}

	nav .nav-close-btn {
		position: absolute;
		top: 3.5rem;
		right: 4rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}

.font_nav_text{
    font-size: 20px;
    font-weight: 600 !important;
}
.font_nav_texts{
    font-size: 30px;
    font-weight: 600 !important;
	transition: all 0.3s !important;
}


