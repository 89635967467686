*{
    
    font-family: 'Kanit', sans-serif !important;
}
body{
    background-color:#1E1F23!important;
    font-family: 'Kanit', sans-serif !important;
}



.page {
    /* Defines the top of each page as the
       edge that should be used for snapping */
    scroll-snap-align: start none;
}
.docScroller {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* Specifies that each element’s snap area should
       align with a 100px offset from the top edge. */
    scroll-padding: 100px 0 0;
    /* Encourages scrolling to end at a snap position when the
        operation completes, if it is near a snap position */
    scroll-snap-type: y proximity;
}


.visually-hidden{
    display: none !important;
}

.text {
    text-align: center;
    font-weight: 200;
    -webkit-text-stroke: 0.2px black;
    -webkit-text-fill-color: white;
          
    font-size: 10px;
    font-family: 'Nunito', sans-serif;
    
}
@media screen and (min-width:1000px) {
    .text {
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
      
        -webkit-text-stroke: 2px black;
	-webkit-text-fill-color: white;
        font-size: 40px;
    }
  }

  
  @keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }
  



  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    z-index: 9999;
    pointer-events: none;
  }
  

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #ddd !important;
  border-radius: 10px !important;
  border: 3px solid #f5f5f5 !important;
}

/* Create the animation */
@keyframes example {
  from {
    width: 0 !important;
  }
  to {
    width: 50px !important;
  }
}

/* Apply the animation to the scrollbar thumb */
::-webkit-scrollbar-thumb {
  animation: example 0.5s ease-in-out !important;
}





.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color:#1E3231;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  color: #fff !important;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #0093a79f;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0093a79f;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #ffffff;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #0093a79f;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: #1369ce;
  background-color: #1E3231;
}













.mainContent {
  background-attachment: fixed;
  background-image: url(./bg1.jpeg)!important;
  background-position: top!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;

}

.pageCon {
 
 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

}