.background_hero{
    background-image: url('./hero.gif');
    background-repeat: no-repeat;
    background-size: cover;
}

.left_hero{
  clip-path: polygon(0 0, 98% 0, 61% 100%, 0% 100%);
    background-color:#1E1F23;
    height: 100vh;
}

.right_hero{
      height: 100vh;
}

.background_mission{
    background-image: url('./image.jpg');
    background-repeat: no-repeat;
    background-size: cover;   
    height: 100%;
}

.aboutBtnContainer {
    /* width: 50%; */
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
  }
  .aboutBtnContainer > button {
    margin-left: 20px;
  }

  @media (max-width: 700px) {
    .token2 {
      height: 700px;
    }
    .aboutLoctionSec,
    .AboutopSec {
      width: 90%;
    }
    .aboutBtnContainer {
      /* width: 90%; */
      flex-wrap: wrap;
  
      /* justify-content: space-around; */
    }
    .aboutBtnContainer > button {
      margin-bottom: 10px;
    }
  }


  .aboutSec1Btn {
    font-size: 16px;
    appearance: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: baseline;
    border: none;
    outline: none;
    outline-offset: 0;
    width: auto;
    line-height: normal;
    font-weight: 400;
    background-color: transparent;
    color: rgb(255, 123, 0);
    padding: 0;
    margin-right: 5px;
  }
  .aboutSec1Btn:focus {
    box-shadow: none !important;
  }
  .aboutSec1Btn:hover {
    background-color: transparent;
    color: black !important;
    text-decoration: underline;
  }

  .navu{
    z-index: 1 !important;
    position: sticky;
    
}


.first_card{
  background-color: black !important;
  height: 100vh;
}


.text_head{
  background: radial-gradient(50% 50% at 50% 50%, rgba(36, 255, 216, 0.79) 33.33%, rgba(194, 163, 255, 0.79) 99.47%, rgba(196, 163, 255, 0.79) 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10px;
}


@media screen and (min-width:1000px) {
  .text_head{
    background: radial-gradient(50% 50% at 50% 50%, rgba(36, 255, 216, 0.79) 33.33%, rgba(194, 163, 255, 0.79) 99.47%, rgba(196, 163, 255, 0.79) 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15px;
  }
  
}



.text_head_under{
  background: radial-gradient(24% 50% at 50% 50%, rgba(36, 255, 216, 0.79) 33.33%, rgba(194, 163, 255, 0.79) 99.47%, rgba(196, 163, 255, 0.79) 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 900;
}



         
.btn-grad-try {
  background-image:radial-gradient(14% 30% at 50% 50%,  rgba(194, 163, 255, 0.79)  23.33%, rgba(36, 255, 54, 0.79) 99.47%,  rgba(36, 255, 216, 0.79)  99.48%);
  margin: 10px;
  padding: 10px 35px;
  text-align: center;
  text-transform: uppercase;
  
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(255, 255, 255) !important;            

  border-radius: 25px;
  display: block;
}

.btn-grad-try:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff !important;
  text-decoration: none;
}



.style_div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}