.card1 {
    height: 200px;
    width: 100%;
   

}
@media screen and (min-width:1000px) {
    .card1 {
        height: 100vh;
        width: 100%;
      
    
    }
  }






.card2 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image2.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card2 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card2:hover {

background-repeat: no-repeat;
}




.card3 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image3.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card3 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card3:hover {
 
background-repeat: no-repeat;
}




.card4 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image4.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card4 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card4:hover {
   
background-repeat: no-repeat;
}




.card5 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image5.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card5 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card5:hover {

background-repeat: no-repeat;
}





.card6 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image6.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card6 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card6:hover {

background-repeat: no-repeat;
}







.card7 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image7.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card7 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card7:hover {
 
background-repeat: no-repeat;
}


















.card8 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image8.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card8 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card8:hover {

background-repeat: no-repeat;
}








.card9 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image9.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card9 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card9:hover {
  
background-repeat: no-repeat;
}

















.card10 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image10.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card10 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card10:hover {

background-repeat: no-repeat;
}




























.card12 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image122.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card12 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card12:hover {

background-repeat: no-repeat;
}














.card11 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image11.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card11 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card11:hover {

background-repeat: no-repeat;
}




















.card13 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image13.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card13 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card13:hover {

background-repeat: no-repeat;
}


















.card14 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image14.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card14 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card14:hover {
 
background-repeat: no-repeat;
}

























.card15 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image15.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card15 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card15:hover {

background-repeat: no-repeat;
}















.card16 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image16.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card16 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card16:hover {

background-repeat: no-repeat;
}
































.card17 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image17.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card17 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card17:hover {

background-repeat: no-repeat;
}


















.card19 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image19.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card19 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card19:hover {
 
background-repeat: no-repeat;
}







.card18 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image18.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card18 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card18:hover {

background-repeat: no-repeat;
}











.card20 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image20.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card20 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card20:hover {

background-repeat: no-repeat;
}





























.card21 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image21.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card21 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card21:hover {
  
background-repeat: no-repeat;
}




















.card22 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image22.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card22 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card22:hover {

background-repeat: no-repeat;
}






















.card23 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image23.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card23 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card23:hover {

background-repeat: no-repeat;
}














.card24 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image24.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card24 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card24:hover {

background-repeat: no-repeat;
}


























.card25 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image25.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card25 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card25:hover {
background-repeat: no-repeat;
}



































.card26 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image26.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card26 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card26:hover {
  
background-repeat: no-repeat;
}





























.card27 {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"./image/image27.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;

}
@media screen and (min-width:1000px) {
    .card27 {
        height: 100vh;
        width: 100%;
      
    
    }
  }

.card27:hover {

background-repeat: no-repeat;
}


























































