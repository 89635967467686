.preloader-1 {
   
  }
  
  .preloader-2 {
    
  }

  
  .preloader-1 .line {
    width: 1px;
    height: 12px;
    background: #fff;
    margin: 0 1px;
    display: inline-block;
    animation: opacity-1 1000ms infinite ease-in-out;
  }
  
  .preloader-2 .line {
    width: 1px;
    height: 12px;
    background: #fff;
    margin: 0 1px;
    display: inline-block;
    animation: opacity-2 1000ms infinite ease-in-out;
  }
  
  .preloader-1 .line-1, .preloader-2 .line-1 { animation-delay: 800ms; }
  .preloader-1 .line-2, .preloader-2 .line-2 { animation-delay: 600ms; }
  .preloader-1 .line-3, .preloader-2 .line-3 { animation-delay: 400ms; }
  .preloader-1 .line-4, .preloader-2 .line-4 { animation-delay: 200ms; }
  .preloader-1 .line-6, .preloader-2 .line-6 { animation-delay: 200ms; }
  .preloader-1 .line-7, .preloader-2 .line-7 { animation-delay: 400ms; }
  .preloader-1 .line-8, .preloader-2 .line-8 { animation-delay: 600ms; }
  .preloader-1 .line-9, .preloader-2 .line-9 { animation-delay: 800ms; }
  
  @keyframes opacity-1 { 
    0% { 
      opacity: 1;
    }
    50% { 
      opacity: 0;
    }
    100% { 
      opacity: 1;
    }  
  }
  
  @keyframes opacity-2 { 
    0% { 
      opacity: 1;
      height: 15px;
    }
    50% { 
      opacity: 0;
      height: 12px;
    }
    100% { 
      opacity: 1;
      height: 15px;
    }  
  }




























.button-40 {
  background-color: #002718;
  border: 1px #fff transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
   
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.button-40:hover {
  background-color: #374151;
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .button-40 {
    padding: .75rem 1.5rem;
  }
}