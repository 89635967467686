
.nine {
    -webkit-perspective: 150rem;
            perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 200px;
    width: 100%;
  }
  @media screen and (min-width:1000px) {
    .nine {
      -webkit-perspective: 150rem;
              perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 100vh;
      width: 100%;
    }
  }

  
  .nine__side {
    height: 100%;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }
  
  .nine__side--front {
    background-color: #fff;
  }
  
  .nine__side--front-1 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url(
"../image//image9.png") ;
background-repeat: no-repeat;
background-size: cover;
vertical-align: middle;
background-position: 0% 0%;
background-size: 100% 100%;
    border-style: none;
  }
  


  
  .nine__side--front-2 {
    background: linear-gradient(-45deg, #f321d7, #ffec61);
  }
  
  .nine__side--front-3 {
    background: linear-gradient(-45deg, #24ff72, #9a4eff);
  }
  
  .nine__side--back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
 
  
  .nine:hover .nine__side--front-1,
  .nine:hover .nine__side--front-2,
  .nine:hover .nine__side--front-3 {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  
  .nine:hover .nine__side--back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  
  .nine__title {
    height: 20rem;
    padding: 4rem 2rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .nine__title--1 .fas {
    font-size: 5rem;
  }
  
  .nine__title--2 .fas {
    font-size: 5rem;
  }
  
  .nine__title--3 .fas {
    font-size: 5rem;
  }
  
  .nine__heading {
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    width: 75%;
  }
  
  .nine__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  
  .nine__details {
    padding: 0 2rem 2rem;
  }
  
  .nine__details ul {
    list-style: none;
    width: 80%;
    margin: 0 auto;
  }
  
  .nine__details ul li {
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
  }
  
  .nine__details ul li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  
  .nine__cta {
    height: 100%;
    width: 100%;
  }
  
  .nine__price-box {
    text-align: center;
    color: #fff;
    margin-bottom: 8rem;
  }
  
  .nine__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  
  .nine__price-value {
    font-size: 6rem;
    font-weight: 100;
  }
  

  


  .carousel-inner {
   height: 100% !important;
}



.carousel-control-prev-icon {
    font-weight: 700;
    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
    font-size: 1.7rem;
    color: #24ff72 !important;
    background-color: #000000 !important;
}
.carousel-control-next-icon {
  font-weight: 700;
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
  font-size: 1.7rem;
  color: #24ff72 !important;
  background-color: #000000 !important;
}

.carousel-control-prev:hover {
  color: #b10404 !important;
  text-decoration: none !important;
  outline: 0;
  opacity: .9;
  animation: none !important;
}




a:hover span {
  transform:none !important;
}