.item-wrapper:nth-child(even){
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.item-wrapper:nth-child(odd){
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.item{
    background: #1E3231;
    border-radius: 41px;

    display: flex;
    flex-direction: row;
    width: 70%;
    color: #FFFFFF;

    margin-bottom: 100px;
}

.item-container{
    padding: 20px;
}

.item .img{
    min-width: 50%;
    border-radius: 41px;
    background-size: cover;
}

.item .learn-more-btn{
    text-transform: none;
    font-weight: 700;
    font-size: 12px;

    color: #FFFFFF;
}

.item .book-btn{
    background: #D0A5C0;
    border-radius: 26px;
    width: 200px;
    height: 40px;
}

.item .button-box{
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 60px;
    gap: 10px;
}