.signup-screen{
    top: 100%;
    position: absolute;
    width: 100%;
    background-image: url(../../img/background2.jfif);

    padding-top: 250px;
}

.signup-screen-top-wave{
    position: absolute;
    top: -50px;
}

.item-list{
    padding: 50px;
}

.signup-container{
    padding: 100px;
    margin: 50px;
    background: #FFFFFF;
    border-radius: 41px;
}

.signup-container-title{
    font-weight: 700;
    font-size: 50px;

    background: radial-gradient(50% 50% at 50% 50%, #27FAD4 58.33%, #A06BFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.signup-btn-wrapper{
    display: flex;
    justify-content: flex-end;
    padding-top: 50px;
    padding-right: 100px;
}

.signup-btn{
    text-transform: none !important;
    background: #49FFDE !important;
    border-radius: 37px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: center !important;
    width: 213px !important;
    height: 48px !important;

    color: #000000 !important;
}

.signup-btn:hover{
    background: #32b19a !important;
}

.explore-new-destinations{
    background: #1E1F23 !important;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}


.destination{
    width: calc(100% - 40vh - 40px);
}