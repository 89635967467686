.nav {
  height: 80px;
  background-color: #1E1F23;
  width: 100%;
  z-index: 100000000;
  display: flex;
  top: 0px;
  backdropFilter: blur(8px);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100000000;
}
