.item-wrapper{
    justify-content: center !important;
    margin-top: 300px;
}

.item{
    z-index: 13;
    height: 450px;
    width: 60%;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}